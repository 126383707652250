<div class=" bg-white">
    <section class="top">
      <div class="title p-5">
        <h1>{{ 'app-title' | translate}}</h1>
        <h5 class="text-center mt-2 ">{{ 'home.description' | translate}}</h5>
      </div>
    </section>
    <section class="page bg-white">
      <div class="parallax2">
        <div class="overlay"></div>
        <h2 class="text-center page-title text-white">{{ 'not-user-title' | translate}}</h2>
      </div>
      <div class="paragraph second">
        <div class="alert alert-warning text-center">
          <small>{{ 'user-explication' | translate}}: <a href="mailto:service@bib.umontreal.ca">service@bib.umontreal.ca</a></small>
        </div>
        <p class="text-center">
          <a href="https://bib.umontreal.ca/"  class="btn btn-outline-dark  mt-2 ml-5">{{ 'not-user-lien' | translate}}</a>
          <a href="/api/user-udem/login"  class="btn btn-outline-success  mt-2 ml-2">{{ 'connexion' | translate}}</a>
        </p>
      </div>
    </section>
</div>
